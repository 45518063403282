import React, { useState } from "react";
import { Question, Answer, AppText, AnswerResult } from "../types";
import CellCheckbox from "../Answer/CellCheckbox.component";
import Feedback from "../Feedback/Feedback.component";
import MediaBox from "../MediaBox/MediaBox.component";
import {
  QuestionContainer,
  QuestionInstructions,
  QuestionContent,
  QuestionAnswersContainer,
  QuestionButtonValidate,
  QuestionMediaContainer,
  QuestionTitle,
  QuestionColumn,
  QuestionContextTitle,
  QuestionContextDescription,
} from "./Question.style";

interface Props {
  question: Question;
  onAnswerQuestion: (result: AnswerResult) => void;
  text?: AppText;
}

const QuestionView: React.FC<Props> = (props) => {
  const [answers, setAnswers] = useState<Answer[]>(props.question.answerList);
  const [isShowingFeedback, setShowFeedback] = useState(false);
  const [isShowingResult, setShowResult] = useState(false);

  const onValidate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let validateQuestion = true;
    if (props.question.showEachAnswerResults && !isShowingResult) {
      setShowResult(true);
      validateQuestion = false;
    }
    if (props.question.feedback && !isShowingFeedback) {
      setShowFeedback(true);
      validateQuestion = false;
      setTimeout(() => {
        const validateButton = document.getElementById("question-validate-button");
        if (validateButton) window.scrollTo(0, validateButton.offsetTop);
      }, 300);
    }

    if (validateQuestion) {
      props.onAnswerQuestion({
        idAnswers: answers.filter((answer) => answer.selected).map((answer) => answer.id),
        idQuestion: props.question.id,
      });
    }
  };

  const onChangeQCUAnswer = (answer: Answer) => {
    setAnswers(
      answers.map((item) => {
        if (item.selected) item.selected = false; // On enlève ceux qui sont cochés
        if (item.id === answer.id) item.selected = !item.selected; // On toggle l'item
        return item;
      }),
    );
  };

  const onChangeQCMAnswer = (answer: Answer) => {
    setAnswers(answers.map((item) => (item.id === answer.id ? { ...item, selected: !item.selected } : item)));
  };

  const onChangeAnswer = (answer: Answer) => {
    props.question.type === "singleAnswer" ? onChangeQCUAnswer(answer) : onChangeQCMAnswer(answer);
  };

  return (
    <QuestionContainer className={"question-internal-container"}>
      {!(isShowingFeedback && props.question.feedback?.display === "replace") && (
        <QuestionContent className="question-content">
          {props.question.context && (
            <QuestionColumn noMedia={props.question.context.media === undefined} className={"question-column context"}>
              <QuestionContextTitle className="question-context-title">
                {props.question.context.title}
              </QuestionContextTitle>
              <QuestionContextDescription className="question-context-description">
                {props.question.context.description}
              </QuestionContextDescription>

              {props.question.context.media && (
                <QuestionMediaContainer className="question-media-container">
                  <MediaBox media={props.question.context.media} />
                </QuestionMediaContainer>
              )}
            </QuestionColumn>
          )}

          <QuestionColumn className={"question-column question"} noContext={props.question.context === undefined}>
            <QuestionTitle className="question-title">{props.question.question}</QuestionTitle>
            <QuestionInstructions className="question-instructions">{props.question.instructions}</QuestionInstructions>
            <QuestionAnswersContainer
              noContext={props.question.context === undefined}
              className="question-answers-container"
            >
              {answers.map((answer) => (
                <CellCheckbox
                  noContext={props.question.context === undefined}
                  key={answer.id}
                  onChange={onChangeAnswer}
                  answer={answer}
                  disabled={isShowingFeedback || isShowingResult}
                  showResult={isShowingResult}
                />
              ))}
            </QuestionAnswersContainer>
          </QuestionColumn>
        </QuestionContent>
      )}

      {isShowingFeedback && props.question.feedback && <Feedback feedback={props.question.feedback} />}

      <QuestionButtonValidate
        id="question-validate-button"
        className="question-button-validate"
        onClick={onValidate}
        disabled={answers.filter((answer) => answer.selected).length === 0}
      >
        {(props.question.showEachAnswerResults && !isShowingResult) ||
        (props.question.showEachAnswerResults && !isShowingResult)
          ? props.text?.validate || "VALIDER"
          : props.text?.next || "SUIVANT"}
      </QuestionButtonValidate>
    </QuestionContainer>
  );
};

export default QuestionView;
