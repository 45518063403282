import styled from "styled-components";

const devices = { mobile: "(max-width: 768px)" };

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.primaryColor};
  scroll-behavior: smooth;
  max-height: 100%;
`;

export const QuestionTitle = styled.h2`
  text-align: center;
  max-width: 100%;
  color: ${({ theme }) => theme.textColor};
`;

interface QuestionAnswersContainerProps {
  noContext?: boolean;
  noMedia?: boolean;
}

export const QuestionColumn = styled.div<QuestionAnswersContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: ${({ noMedia }) => (noMedia ? "center" : "flex-start")};
  max-width: ${(props) => (props.noContext ? "100%" : "50%")};
  @media ${devices.mobile} {
    max-width: 100%;
    width: 100%;
  }
`;

export const QuestionContextTitle = styled.h2`
  color: ${({ theme }) => theme.textColor};
`;

export const QuestionContextDescription = styled.p`
  color: ${({ theme }) => theme.textColor};
  font-weight: normal;
`;

export const QuestionInstructions = styled.p`
  color: ${({ theme }) => theme.textColor};
  font-weight: normal;
  font-size: small;
`;

export const QuestionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  flex-wrap: wrap;
`;

export const QuestionAnswersContainer = styled.div<QuestionAnswersContainerProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  flex-direction: ${(props) => (props.noContext ? "row" : "column")};
  align-items: center;
`;

export const QuestionMediaContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 1em;
`;

export const QuestionButtonValidate = styled.button`
  width: auto;
  height: 3em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 0.5em;
  color: #ffffff;
  border-color: transparent;
  margin: 1em;
  background-color: ${(props) => (props.disabled ? "#A9A9A9" : props.theme.primaryColor)};

  font-weight: bold;
  font-size: large;
  transition: all 0.2s ease-in-out;
  letter-spacing: 0.1em;
  ${(props) =>
    !props.disabled &&
    `
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.2);
    :hover {
      transform: scale(1.05);
    }
    `}
  :focus {
    outline: 0;
  }
`;
