import React, { useContext } from "react";
import { Answer } from "../types";
import IconValid from "../assets/IconValid";
import IconInvalid from "../assets/IconInvalid";
import {
  QuestionAnswerContainer,
  AnswerHiddenCheckbox,
  AnswerCheckbox,
  AnswerCheckboxPill,
} from "./CellCheckbox.style";
import { ThemeContext } from "styled-components";

interface Props {
  noContext?: boolean;
  answer: Answer;
  disabled?: boolean;
  showResult?: boolean;
  onChange: (answer: Answer) => void;
}

const CellCheckbox: React.FC<Props> = (props) => {
  const theme = useContext(ThemeContext);
  const onChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.showResult || props.onChange(props.answer);
  };
  return (
    <QuestionAnswerContainer className="answer-container" noContext={props.noContext}>
      <AnswerHiddenCheckbox type="checkbox" className="answer-hidden-checkbox" onClick={onChange} />
      <AnswerCheckbox
        checked={props.answer.selected}
        disabled={props.disabled}
        showResult={props.showResult}
        isValid={props.answer.valid}
        className="answer-checkbox"
        onClick={onChange}
      >
        {props.answer.selected && <AnswerCheckboxPill className="answer-checkbox-pill" />}
        {props.answer.text}
        {props.showResult && props.answer.valid && (
          <IconValid
            className={"answer-result-icon"}
            style={{ position: "absolute", right: "1em" }}
            fill={theme.validColor || "#0D9353"}
          />
        )}
        {props.showResult && !props.answer.valid && (
          <IconInvalid style={{ position: "absolute", right: "1em" }} className="answer-result-icon" fill={theme.invalidColor || "#DD3E3E"} />
        )}
      </AnswerCheckbox>
    </QuestionAnswerContainer>
  );
};

export default CellCheckbox;
