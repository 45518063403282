import React from "react";
import MediaBox from "../MediaBox/MediaBox.component";
import { Feedback } from "../types";
import {
  FeedbackContainer,
  FeedbackContent,
  FeedbackDescriptionContainer,
  FeedbackMediaContainer,
  FeedbackTitle,
} from "./Feedback.style";

interface Props {
  feedback: Feedback;
}

const FeedbackView: React.FC<Props> = (props) => {
  return (
    <FeedbackContainer className="feedback-container">
      <FeedbackTitle className="feedback-title">{props.feedback.title}</FeedbackTitle>
      <FeedbackContent className="feedback-content">
        {props.feedback.media && (
          <FeedbackMediaContainer className="feedback-media-container">
            <MediaBox media={props.feedback.media} />
          </FeedbackMediaContainer>
        )}
        {props.feedback.description && (
          <FeedbackDescriptionContainer className="feedback-description-container">
            <p className="feedback-description">{props.feedback.description}</p>
          </FeedbackDescriptionContainer>
        )}
      </FeedbackContent>
    </FeedbackContainer>
  );
};

export default FeedbackView;
